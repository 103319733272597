<template>
  <div class="guide-cnt5">
    <h6 class="h6">配送范围</h6>
    <div class="cnt">
      <p class="p">微微定支持全国大部分地区包邮配送，偏远地区除外，具体产品包邮范围请以微微定最新物流政策为准，以下区域供参考：</p>
      <table>
        <tr>
          <th>产品类别</th>
          <th>不包邮地区</th>
        </tr>
        <tr>
          <td>抽纸、方盒纸巾、荷包纸巾、标准手帕纸巾、扑克纸巾、湿巾、散装纸</td>
          <td>甘肃、青海、宁夏、新疆、西藏、内蒙古、港澳台地区</td>
        </tr>
        <tr>
          <td>奶茶杯、纸杯、牙签</td>
          <td>海南、甘肃、青海、宁夏、新疆、西藏、内蒙古、港澳台地区</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt5',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt5 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
    table {
      border-collapse: collapse;width: 100%;
      tr {
        th {
          border:1px solid #333;height: 50px;line-height: 50px;
        }
        td {
          border:1px solid #333;padding:15px;
        }
      }
    }
  }
}
</style>
